<template>
  <PContainer fluid class="equifax-resolve mt-5">
    <PRow align-h="center" v-if="workflowLoading">
      <PSpinner />
    </PRow>
    <ThreeColumn v-else>
      <template #left>
        <div
          v-if="currentStep !== EquifaxResolveSteps.SUBMIT_EQUIFAX_RESOLVE"
          class="icon-wrapper text-secondary d-flex align-items-center px-3"
          @click="handleBackNavigation"
        >
          <font-awesome-icon class="icon" :icon="['fas', 'arrow-left']" />
          <p>Back</p>
        </div>
      </template>
      <template #center>
        <StartEquifaxResolve
          v-if="currentStep === EquifaxResolveSteps.START_EQUIFAX_RESOLVE"
          :workflowId="workflowId"
          :clientId="clientID"
          :clientName="clientName"
          @proceedDualSourceIdVerification="handleDualSourceVerification"
          @proceedPhotoIdVerification="handlePhotoIdVerification"
        />
        <EquifaxDualSourceId
          v-if="currentStep === EquifaxResolveSteps.DUALSOURCE_ID_VERIFICATION"
          :workflowId="workflowId"
          :clientId="clientID"
          :clientName="clientName"
          @uploadDocuments="handleUploadDocuments"
          @resolveEquifaxSteps="resolveEquifaxSteps"
          @proceedToConfirmationPage="proceedToConfirmationPage"
        />
        <EquifaxPhotoId
          v-if="currentStep === EquifaxResolveSteps.PHOTO_ID_VERIFICATION"
          :workflowId="workflowId"
          :clientId="clientID"
          :clientName="clientName"
          @uploadDocuments="handleUploadDocuments"
          @resolveEquifaxSteps="resolveEquifaxSteps"
          @proceedToConfirmationPage="proceedToConfirmationPage"
        />
        <EquifaxConfirmationPage
          v-if="currentStep === EquifaxResolveSteps.SUBMIT_EQUIFAX_RESOLVE"
          :workflowId="workflowId"
          :clientId="clientID"
          :clientName="clientName"
          @goToWorkflowPage="goToWorkflowPage"
        />
      </template>
      <template #right />
    </ThreeColumn>
  </PContainer>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { WORKFLOW, DOCUMENT_SOURCE } from 'Services/constants';
import { sendEquifaxCheck, fetchWorkflowDetails } from 'Services/api/Workflows';
import {
  updateEquifaxDaulSourceIdentifcation,
  updateEquifaxPhotoIdentifcation,
} from '../../../services/api/EquifaxResolve';
import { uploadDocuments } from '../../../helpers';

const EquifaxResolveSteps = {
  START_EQUIFAX_RESOLVE: 1,
  DUALSOURCE_ID_VERIFICATION: 2,
  PHOTO_ID_VERIFICATION: 3,
  SUBMIT_EQUIFAX_RESOLVE: 4,
};
const EQUIFAX_DOCUMENT_TYPE = '25'; // TODO: confirm that this is the correct document type

export default {
  name: 'EquifaxResolve',
  components: {},
  computed: {
    ...mapGetters(['activeClient']),
    workflow() {
      return this.$store.state.workflow.selectedWorkflow;
    },
    workflowId() {
      return Number(this.$route.params.workflowID);
    },
    clientID() {
      return this.workflow?.clientInfo?.id;
    },
    clientName() {
      let title = '';
      if (this.workflow?.clientInfo) {
        title = this.workflow?.clientInfo?.display_name;
      } else if (this.workflow?.type === WORKFLOW.TYPES.ips_group) {
        title = this.workflow?.ipsGroupInfo?.name;
      }
      return title;
    },
    features() {
      return this.$store.state.app.features;
    },
    documentSource() {
      return _.invert(DOCUMENT_SOURCE);
    },
  },
  data() {
    return {
      EquifaxResolveSteps,
      currentStep: EquifaxResolveSteps.START_EQUIFAX_RESOLVE,
      workflowLoading: true,
    };
  },
  async mounted() {
    this.workflowID = this.$route.params.workflowID;
    Promise.all([
      this.$store.dispatch('fetchWorkflowV2IpsGroup', this.workflowID),
      this.$store.dispatch('fetchWorkflowV2Client', this.workflowID),
    ])
      .then(() => {
        this.workflowLoading = false;
      })
      .catch((err) => {
        this.snackBar('fail', 'We had trouble fetching data. Please reload the page');
      });
  },
  methods: {
    handleDualSourceVerification() {
      this.currentStep = EquifaxResolveSteps.DUALSOURCE_ID_VERIFICATION;
    },
    handlePhotoIdVerification() {
      this.currentStep = EquifaxResolveSteps.PHOTO_ID_VERIFICATION;
    },
    proceedToConfirmationPage() {
      this.currentStep = EquifaxResolveSteps.SUBMIT_EQUIFAX_RESOLVE;
    },
    goToWorkflowPage() {
      this.$router.push({ path: `/workflowV2/${this.workflowID}` });
    },
    async handleBackNavigation() {
      switch (this.currentStep) {
        case EquifaxResolveSteps.DUALSOURCE_ID_VERIFICATION:
          this.currentStep = EquifaxResolveSteps.START_EQUIFAX_RESOLVE;
          break;
        case EquifaxResolveSteps.PHOTO_ID_VERIFICATION:
          this.currentStep = EquifaxResolveSteps.START_EQUIFAX_RESOLVE;
          break;
        default:
          this.goToWorkflowPage();
          break;
      }
    },
    async handleUploadDocuments(payload) {
      const docIds = await this.uploadDocuments(payload);
      if (docIds.length > 0) {
        await this.updateEquifaxIdentificationWithDocument(payload, docIds[0]);
      }
    },
    async resolveEquifaxSteps(payload) {
      const { id, type } = await fetchWorkflowDetails(payload.workflow_id);
      await sendEquifaxCheck({ id, type }, true);
    },
    async updateEquifaxIdentificationWithDocument(payload, docId) {
      if (payload.type === 'DUALSOURCE_ID_VERIFICATION') {
        await updateEquifaxDaulSourceIdentifcation(payload.id, { document: docId });
      } else if (payload.type === 'PHOTO_ID_VERIFICATION') {
        await updateEquifaxPhotoIdentifcation(payload.id, { document: docId });
      }
    },
    async uploadDocuments(payload) {
      const docIds = [];
      // check for documents first
      if (payload.documents?.length > 0) {
        const uploadResp = await uploadDocuments({
          documents: payload.documents,
          client: { id: payload.client_id },
          docType: EQUIFAX_DOCUMENT_TYPE,
          workFlow: payload.workflow_id,
          documentSource: this.documentSource.Manual,
        });
        // update equifax idenitification records with document ids
        docIds.push(...uploadResp.map((r) => r.value.doc_id).filter((e) => e !== undefined));
      }
      return docIds;
    },
  },
};
</script>

<style lang="scss" scoped>
.equifax-resolve {
  font-family: Work Sans !important;
  .icon-wrapper {
    cursor: pointer;
    color: #0b4a7a !important;
    margin-bottom: 1rem;

    &:hover {
      color: #0b4a7a !important;
      background-color: inherit !important;
      text-decoration: underline;
    }
  }
  .icon {
    margin-right: 0.5rem;
  }
}
</style>
